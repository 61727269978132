import type {
  Maybe,
  OfferDynamicDiscount,
  Product,
  SaleFunnelDiscount,
  SaleFunnelDiscountEdge,
  SaleFunnelOffer,
} from '@/types/graphql';
import { defineStore } from 'pinia';

export type Discount = Maybe<
  Maybe<
    Pick<SaleFunnelDiscountEdge, 'cursor'> & {
      node?: Maybe<
        Pick<SaleFunnelDiscount, 'id' | 'objectType' | 'objectBaseID' | 'title' | 'type' | 'value' | 'position'>
      >;
    }
  >
>;

export type TProduct = Maybe<
  Pick<
    Product,
    | 'averageRating'
    | 'baseID'
    | 'createdAt'
    | 'currentVersion'
    | 'deletedAt'
    | 'description'
    | 'descriptionMeta'
    | 'featuredImageId'
    | 'handle'
    | 'id'
    | 'platform'
    | 'publishedAt'
    | 'reviewCount'
    | 'sku'
    | 'status'
    | 'templateSuffix'
    | 'title'
    | 'titleMeta'
    | 'updatedAt'
    | 'vendor'
  >
>;

export const useSalesFunnelStore = defineStore({
  id: 'salesFunnel',
  state: () => ({
    discounts: [] as Array<Discount>,
    initProductId: null as string | null,
    salesPageProductIdAssignment: undefined as string | null | undefined,
    productOfferIds: undefined as Array<string> | undefined,
    productAssignment: null as TProduct | null,
    productDiscounts: [] as Array<Discount>,
    offers: [] as { node: SaleFunnelOffer }[],
    isActivated: false as boolean,
    dynamicDiscount: null as OfferDynamicDiscount | null,
  }),
  actions: {
    setOffers(data: { node: SaleFunnelOffer }[]) {
      this.offers = data;
    },
    setDiscounts(discountsParam: Array<Discount>) {
      this.discounts = discountsParam;
    },
    setInitProductId(productId: string | null) {
      this.initProductId = productId;
    },
    setSalesPageProductIdAssignment(productId: string | null) {
      this.salesPageProductIdAssignment = productId;
    },
    setProductOffers(productIds: Array<string>) {
      this.productOfferIds = productIds;
    },
    setProductAssignment(product: TProduct) {
      this.productAssignment = product;
    },
    setProductDiscounts(products: Array<Discount>) {
      this.productDiscounts = products;
    },
    setIsActivated(value: boolean) {
      this.isActivated = value;
    },
    setDynamicDiscount(dynamicDiscount: OfferDynamicDiscount | null) {
      this.dynamicDiscount = dynamicDiscount;
    },
  },
});
