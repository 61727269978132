import { useSidebarStore } from '@/modules/editor/modules/sidebar/stores/sidebar';
import useInteractionStore from '../stores';
import type { InteractionSettingType } from '../types';
import { InteractionModeType } from '../types';
import { emitUpdateSelectOnPageInteraction } from '@/modules/editor/modules/preview/utils/emitToIframe';
import useNotification from '@/composables/useNotification';

export const useInteractionAction = () => {
  const sidebarStore = useSidebarStore();
  const interactionStore = useInteractionStore();
  const { handleSuccess } = useNotification();
  const INTERACTION_UNSUPPORTED_ELEMENTS = ['Header'];

  const isBlockedByInteraction = computed(() => sidebarStore.mode === 'interaction');

  const handleEmitSelectOnPage = (
    value: boolean,
    callback?: () => void,
    extraData?: {
      mode?: InteractionModeType;
      settingType?: InteractionSettingType;
    },
  ) => {
    interactionStore.setSelectType(extraData?.mode || InteractionModeType.ELEMENT);
    emitUpdateSelectOnPageInteraction(value, callback, extraData);
  };

  const checkIsElementSupported = (elementTag: string, isShowMsg: boolean = true) => {
    if (!INTERACTION_UNSUPPORTED_ELEMENTS.includes(elementTag)) return true;
    if (isShowMsg) handleSuccess('Element doesn’t supported');
    return false;
  };
  return {
    isBlockedByInteraction,
    handleEmitSelectOnPage,
    checkIsElementSupported,
  };
};
